<template>
    <tr>
        <td class="none-breaking-text">
            <deq-user :user="log.user" />
        </td>
        <td class="none-breaking-text date">
            <deq-date :date="log.date" format="D MMM YYYY HH:mm" />
        </td>
        <td class="none-breaking-text auxiliary-column">
            <span class="muted">{{moment(log.date).fromNow()}}</span>
        </td>

        <td class="none-breaking-text auxiliary-column">
            <span class="muted" v-if="log.repeatCount"> x {{ log.repeatCount }}</span>
        </td>

        <td width="100%">
            {{ descriptionTrimmed }}
        </td>
    </tr>
</template>

<script>
import moment from 'moment';
import DeqDate from "@/components/formatters/DeqDate";
import DeqUser from "@/components/formatters/DeqUser";

export default {
    name: "DashboardEditLog",
    props: {
        dashboard: Object,
        log: Object,
        showClientEdits: {
            type: Boolean,
            default: true
        },
        showStaffEdits: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return { moment }
    },
    components: {DeqUser, DeqDate},
    computed: {
        descriptionTrimmed() {
            if (!this.dashboard) return this.log.description

            const descriptionWithNameExtracted = this.log.description.split(`Report [${this.dashboard.name}:${this.dashboard.id}]`).join('').trim();
            return descriptionWithNameExtracted.slice(0,1).toUpperCase() + descriptionWithNameExtracted.slice(1)
        }
    }
}
</script>

<style lang="sass" scoped>
    .auxiliary-column
        text-align: right
</style>