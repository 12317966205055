<template>
    <section :class="`user-activity${className ? ' ' + className : ''}`">
        <div class="user-activity__log" >
            <div class="user-activity__toolbar">
                <h4>
                    <deq-number v-if="dashboards.newerDashboards.length !== 0" :number="dashboards.newerDashboards.length" />
                    <span v-else>No</span>
                    {{ formatPlural(dashboards.newerDashboards.length, 'dashboard') }} edited
                </h4>

                <div class="user-filters" v-if="dashboards.newerDashboards.length > 0">
                    <label v-if="dashboardEditors.clients.length > 0">
                        <input v-if="user.admin" type="checkbox" v-model="showClientEditsChecked">
                        {{ user.admin ? 'Client' : 'User'}} edits
                        <span class="muted">
                            <deq-number :number="dashboards.byClients.length" />
                            {{ formatPlural(dashboards.byClients.length, 'dashboard')}},
                            <span @mouseover="showClientEditorsTooltip">
                                <deq-number :number="dashboardEditors.clients.length" />
                                {{ formatPlural(dashboardEditors.clients.length, 'user')}}
                                <i class="symbol-info"></i>
                            </span>
                        </span>
                    </label>

                    <label v-if="dashboardEditors.brandsEyeUsers.length > 0">
                        <input type="checkbox" v-model="showBrandsEyeEdits">
                        DataEQ edits
                        <span class="muted">
                            <deq-number :number="dashboards.byBrandsEyeUsers.length" />
                            {{ formatPlural(dashboards.byBrandsEyeUsers.length, 'dashboard')}}<!--        comment out whitespace
                            --><span v-if="user.admin" @mouseover="showBrandsEyeEditorsTooltip"><!-- for better comma placement
                                -->, <deq-number :number="dashboardEditors.brandsEyeUsers.length" />
                                {{ formatPlural(dashboardEditors.brandsEyeUsers.length, 'user')}}
                                <i class="icon-info-circled"></i>
                            </span>
                        </span>
                    </label>

                    <span class="muted" v-if="dashboardEditors.clients.length === 0">
                        No {{ user.admin ? 'Client' : 'User'}} edits
                    </span>
                    <span class="muted" v-if="dashboardEditors.brandsEyeUsers.length === 0">
                        No DataEQ edits
                    </span>
                </div>
            </div>
            <template v-if="dashboards.newerDashboards.length > 0 && newerDashboardsFilteredByUserType.length === 0">
                <div class="muted">No dashboards selected</div>
            </template>

            <table v-else-if="dashboards.newerDashboards.length > 0" class="table">
                <template v-for="dashboard in newerDashboardsFilteredByUserType">
                    <thead :key="dashboard.id">
                        <tr>
                            <th style="text-align: left;" colspan="5">
                                {{ dashboard.name }}
                                <slotted-tag v-if="dashboard.deleted"
                                             no-close
                                             tooltip="This dashboard has been deleted">
                                    <i class="symbol-info"></i> Deleted
                                </slotted-tag>
                                <a v-if="!dashboard.deleted" class="dashboard-link" :href="dashboard.link"><i class='icon icon-forward-2'></i></a>
                            </th>
                        </tr>
                    </thead>
                    <tbody :key="dashboard.id + 'body'">
                        <dashboard-edit-log
                            v-for="edit in dashboard.edits"
                            :key="edit.date"
                            :log="edit"
                            :showClientEdits="showClientEdits"
                            :showBrandsEyeEdits="showBrandsEyeEdits"
                            :dashboard="dashboard"
                        />
                    </tbody>
                </template>
            </table>

            <template v-if="dashboards.olderEdits.length > 0">
                <h4>Older edits</h4>
                <table>
                    <tbody>
                        <dashboard-edit-log
                            v-for="(edit,idx) in dashboards.olderEdits"
                            :key="idx"
                            :showClientEdits="showClientEdits"
                            :showBrandsEyeEdits="showBrandsEyeEdits"
                            :log="edit"
                        />
                    </tbody>
                </table>
            </template>
        </div>
        <div class="user-activity__users-list" v-if="sortedClients.length + sortedBrandsEyeUsers.length === 9">
            <h4>Not accessed</h4>
        </div>
        <div class="user-activity__users-list" v-if="sortedClients.length > 0">
            <h4>
                Accessed by <deq-number :number="sortedClients.length"/> {{formatPlural(sortedClients.length, user.admin ? 'client' : 'user')}}
                 <div v-if="!user.admin && sortedBrandsEyeUsers.length > 0 ">
                    and by DataEQ
                </div>
            </h4>
            <ul>
                <li v-for="user in sortedClients" :key="user.id">
                    <user :user="user" />
                </li>
            </ul>
        </div>
        <div class="user-activity__users-list" v-if="user.admin && sortedBrandsEyeUsers.length > 0">
            <h4>
                Accessed by <deq-number :number="sortedBrandsEyeUsers.length"/> {{formatPlural(sortedBrandsEyeUsers.length, 'staff member')}}
            </h4>
            <ul>
                <li v-for="user in sortedBrandsEyeUsers" :key="user.id" :class="getUserType(user) === 'PAST_STAFF' ? 'muted' : ''">
                    <user :user="user" />
                </li>
            </ul>
        </div>

    </section>
</template>

<script>
import User from "@/components/formatters/DeqUser";
import DeqNumber from "@/components/formatters/DeqNumber";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import DashboardEditorsTooltip from './DashboardEditorsTooltip';
import DashboardEditLog from "./DashboardEditLog";
import moment from 'moment';
import {getUserType, isBrandsEyeUser} from '@/components/formatters/helpers';
import SlottedTag from "@/components/tags/SlottedTag";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import {formatPlural} from "@/app/utils/Format";

export default {
    name: "UserActivity",
    store: VuexStore,
    props: {
        clients: {
            type: Array,
            required: true,
            default: () => []
        },
        brandsEyeUsers: {
            type: Array,
            required: true,
            default: () => []
        },
        dashboards: {
            type: Object,
            required: true
        },
        className: String
    },
    components: {DeqNumber, SlottedTag, User, DashboardEditLog },
    data() {
        return {
            moment,
            getUserType,
            showBrandsEyeEdits: !!VuexStore.state.user.admin,
            showClientEditsChecked: true
        }
    },
    computed: {
        ...mapState(['user']),

        dashboardEditors() {
            const dashboardUserIds = new Set()
            this.dashboards.newerDashboards.forEach(dashboard => dashboard.edits.forEach(edit => dashboardUserIds.add(edit.user.id)))
            return {
                clients: this.sortedClients.filter(user => dashboardUserIds.has(user.id)),
                brandsEyeUsers: this.sortedBrandsEyeUsers.filter(user => dashboardUserIds.has(user.id))
            }
        },
        showClientEdits() {
            return !this.user.admin || this.showClientEditsChecked
        },
        newerDashboardsFilteredByUserType() {
            return this.dashboards.newerDashboards.filter(dashboard => (
                   ( this.showBrandsEyeEdits && dashboard.brandsEyeUserEdited )
                || ( this.showClientEdits && dashboard.clientEdited )
            )).map(dashboard => {
                const filteredEdits = dashboard.edits.filter(edit => {
                    const byBrandsEyeUser = isBrandsEyeUser(edit.user);
                    return ( this.showBrandsEyeEdits && byBrandsEyeUser) || ( this.showClientEdits && !byBrandsEyeUser)
                })
                return Object.assign( dashboard, { edits: filteredEdits } )
            })
        },
        sortedClients() {
            return this.clients.slice(0).sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
        },
        sortedBrandsEyeUsers() {
            return this.brandsEyeUsers.slice(0).sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
        }
    },
    methods: {
        formatPlural,

        showClientEditorsTooltip(event) {
            showTooltipComponent(event.currentTarget, DashboardEditorsTooltip, { users: this.dashboardEditors.clients })
        },
        showBrandsEyeEditorsTooltip(event) {
            showTooltipComponent(event.currentTarget, DashboardEditorsTooltip, { users: this.dashboardEditors.brandsEyeUsers })
        }
    }

}
</script>

<style lang="sass" scoped>
.user-activity
    display: flex
    &__log
        flex-grow: 1
        tr th
            .dashboard-link
                opacity: 0
                transition: opacity 0.1s
                transition-delay: 0
            &:hover
                .dashboard-link
                    opacity: 1
                    transition: opacity 0.2s
                    transition-delay: 0.2s
        .auxiliary-column
            border-left: none
            text-align: right
            padding-right: 8px
        td
            word-break: break-word
            .date
                text-align: right
    &__toolbar
        display: flex
        justify-content: space-between
        .user-filters
            margin-top: 10px
            > label,
            > span
                user-select: none
                display: inline-block
                margin-left: 12px
                input[type="checkbox"]
                    margin-top: 0
            i
                font-size: 0.8em

    &__users-list
        width: 250px
        padding-left: 20px
        flex-shrink: 1
        ul
            padding: 0
            margin: 0
        li
            list-style: none
            padding: 0
            margin: 0 0 12px

</style>