<template>
   <div class="beef-tooltip fantastic-chart--tooltip">
        <deq-user :user="user"  v-for="user in users" :key="user.id"/>
    </div>
</template>

<script>
import DeqUser from "@/components/formatters/DeqUser";
export default {
    name: "DashboardEditorsTooltip",
    props: {
        users: Array
    },
    components: {DeqUser  }
}
</script>

<style lang="sass" scoped>
.be-user
    display: block
</style>